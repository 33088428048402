import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomePageState, MeasurementPageState, PageKey, ResultPageState, ResultTabKey } from "./pageState";
import * as localStorage from "src/helper/storage/localStorage";
import { AppThunk } from "src/store";
import { Phase } from "../measurement/types";
import * as LS from '../../helper/storage';

interface PageManagerState {
    currentPage: PageKey;
    homeState: HomePageState;
    measurementState: MeasurementPageState;
    resultState: ResultPageState;
}

const initialState: PageManagerState = {
    currentPage: PageKey.Home,
    homeState: {},
    measurementState: {},
    resultState: {
        currentTab: ResultTabKey.CurrentMesasurement,
        showSurveyModal: false,
        lastSurveyDate: null
    },
}

export const initPageManager = (): AppThunk => (dispatch) => {
    const legacyOfferedSurvey = localStorage.getOfferedSurvey();
    const lastSurveyDate = localStorage.getLastSurveyDate();

    if ((legacyOfferedSurvey === true) && (lastSurveyDate === null)) {
        const currentDate = new Date();
        localStorage.setLastSurveyDate(currentDate)
        dispatch(setLastSurveyDate(currentDate))
    } else {
        dispatch(setLastSurveyDate(lastSurveyDate))
    }
};

export const updateResultPageTab: (tabKey: ResultTabKey) => AppThunk = (tabKey) => (dispatch) => {
    dispatch(pageManagerSlice.actions.updateResultPageTabPure(tabKey))
    dispatch(checkAndHandleSurvey())
}

export const checkAndHandleSurvey = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const lastSurveyDate = state.pageManager.resultState.lastSurveyDate;

    const currentDate = new Date();
    const thirtyDaysAgo = new Date().setDate(currentDate.getDate() - 30);

    const isVF =
        state.measurement.kind === Phase.FinishedNoModem || state.measurement.kind === Phase.FinishedWithModem
            ? state.measurement.prepareResult.init.connection.isp === 'Vodafone GmbH'
            : false;

    if (isVF && (lastSurveyDate === null || lastSurveyDate.getTime() <= thirtyDaysAgo)) {
        dispatch(setShowSurvey(true));

        dispatch(setLastSurveyDate(currentDate));
        LS.setLastSurveyDate(currentDate);
    }
};

export const pageManagerSlice = createSlice({
    name: 'pageManager',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<PageKey>) => {
            state.currentPage = action.payload;
            return state
        },
        updateResultPageTabPure: (state, action: PayloadAction<ResultTabKey>) => {
            state.resultState.currentTab = action.payload;
            return state
        },
        setShowSurvey: (state, action: PayloadAction<boolean>) => {
            state.resultState.showSurveyModal = action.payload
            return state
        },
        setLastSurveyDate: (state, action: PayloadAction<Date | null>) => {
            state.resultState.lastSurveyDate = action.payload
            return state
        }
    }
});

export const {
    updatePage,
    setShowSurvey,
    setLastSurveyDate
} = pageManagerSlice.actions;

export default pageManagerSlice;