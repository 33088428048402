import { NetworkQualitySDK } from "@visonum/network-quality-sdk";
import { SDK_KIND, NODE_ENV } from "../config";
import { getMeasurementModeFromUrl } from "./getMeasurementModeFromUrl";
import { logger } from "../helper/logger";
import { isRunningInBrowser } from "../helper/ssr";
import { createSdkJs } from "./js/createSdkJs";
import { createSdkFusion } from "./fusion/createSdkFusion";
import { SyntheticNetworkQuality } from "./synthetic/SyntheticNetworkQuality";

declare global {
    interface Window {
        sdk: NetworkQualitySDK;
    }
}

let sdk: NetworkQualitySDK | null = null;

export function getSdk(): NetworkQualitySDK {
    if (sdk !== null) {
        return sdk;
    }

    const url = isRunningInBrowser() ? window.location.href : null;
    sdk = createSdk(url);

    return sdk;
}

const createSdk = (url: string | null) => {
    logger.info(`createSdk SDK_KIND = ${SDK_KIND}`);

    const mode = getMeasurementModeFromUrl(url);

    const jsSdk = createSdkJs(url);

    const createSdkAccordingToSdkKind = (): NetworkQualitySDK => {
        switch (SDK_KIND) {
            case "JS":
                return jsSdk;
            case "Fusion":
                return createSdkFusion(mode, jsSdk);
            case "Synthetic":
                return new SyntheticNetworkQuality(mode, 0.5);
        }
    }

    const realSdk = createSdkAccordingToSdkKind();
    const sdk = realSdk;

    if (NODE_ENV === "test" && isRunningInBrowser()) {
        window.sdk = sdk;
        logger.info("window.sdk has been set.");
    }

    return sdk;
}

