import { Models, SpeedtestState, createNqError } from "@visonum/network-quality-sdk";
import { NQRecord } from "../history/types";
import { nullOrValue } from "../../helper/utils/nullOrValue";
import { AppThunk } from "../../store";
import { VARIANT } from "../../config";
import { matomoPushEvent } from "../matomo/matomoEvents";
import { plainDateToDate } from "../../../src/types";

export interface PartialServiceInput {
    ispFootprint: string;
    partialService: Models.PartialServiceModel,
    speedtestState: SpeedtestState,
    date: Date;
    lastModemResetDate: Date | null;
    callbackCounter: number;
    currentDownloadSpeed: number;
    bookedDownloadSpeedMax: number | null;
}

export enum NetworkImprovementTipsStatus {
    Default = "Default",
    OnFirstPlace = "OnFirstPlace",
    Hidden = "Hidden",
}

export enum PartialServiceDialogKind {
    None = "None",
    Reset = "Reset",
    Callback = "Callback"
}

export enum TipStatus {
    Hidden = "Hidden",
    Visible = "Visible",
}

export interface PartialServiceState {
    partialServiceDialogKind: PartialServiceDialogKind;
    networkImprovementTipsStatus: NetworkImprovementTipsStatus;
    modemResetTipStatus: TipStatus;
    callbackTipStatus: TipStatus;
}

const defaultState: PartialServiceState = {
    partialServiceDialogKind: PartialServiceDialogKind.None,
    networkImprovementTipsStatus: NetworkImprovementTipsStatus.Default,
    modemResetTipStatus: TipStatus.Hidden,
    callbackTipStatus: TipStatus.Hidden,
}

/* pure */
export const getDays = (lastDate: Date | null, currentDate: Date): number | null =>
    lastDate === null ? null : (currentDate.getTime() - lastDate.getTime()) / (1000 * 3600 * 24);

/* pure */
const getIsFirstRun = (days: number | null) => days === null || days > 2;

export const getInputFromNqRecord = (record: NQRecord): PartialServiceInput | null => {
    if (record.partialService === null) {
        return null;
    }

    return {
        ispFootprint: record.prepareResult.init.connection.ispFootprint ?? "",
        partialService: record.prepareResult.init.partialService,
        speedtestState: record.modemFinal.speedtestState,
        date: plainDateToDate(record.date),
        lastModemResetDate: record.partialService.lastModemResetDate,
        callbackCounter: record.partialService.callbackCounter,
        currentDownloadSpeed: record.downloadFinal.speed,
        bookedDownloadSpeedMax: nullOrValue(record.prepareResult.init.modem.bookedDownloadSpeedMax),
    }
}

/* pure */
export const getPartialServiceState = (input: PartialServiceInput | null): PartialServiceState => {
    if (input === null || input.ispFootprint !== "UM") {
        return defaultState;
    }

    const modemResetDays = getDays(input.lastModemResetDate, input.date);

    if (modemResetDays !== null && modemResetDays < 0) {
        throw createNqError("Cl-9", `modemResetDays less than 0 (${modemResetDays})`);
    }

    const downloadIndex = input.partialService.downloadIndex;
    const uploadIndex = input.partialService.uploadIndex;

    if (downloadIndex === null || uploadIndex === null) {
        return defaultState;
    }

    if (downloadIndex < 0) {
        throw createNqError("Cl-10", `downloadIndex less than 0 (${downloadIndex})`);
    }

    if (uploadIndex < 0) {
        throw createNqError("Cl-11", `uploadIndex less than 0 (${downloadIndex})`);
    }

    const firstRun = getIsFirstRun(modemResetDays);
    const speedtestState = input.speedtestState;
    const downIsOk = speedtestState.downloadUdp === null ? speedtestState.downloadClient : speedtestState.downloadUdp;
    const upIsOk = speedtestState.uploadClient;
    const bookedDownloadSpeedMax = input.bookedDownloadSpeedMax;
    const udpIsOk = speedtestState.downloadUdp === true && bookedDownloadSpeedMax !== null;

    const downloadIndexThreshold = 10;
    const uploadIndexThreshold = 4;

    if (firstRun) {
        // Case 1, Case 2
        if ((modemResetDays === null || modemResetDays > 30)) {
            // Case 1 or Case 2
            if ((!downIsOk && downloadIndex > 0 || !upIsOk && uploadIndex > 0) ||
                (downIsOk && downloadIndex >= downloadIndexThreshold || upIsOk && uploadIndex >= uploadIndexThreshold)) {
                return {
                    partialServiceDialogKind: PartialServiceDialogKind.Reset,
                    networkImprovementTipsStatus: NetworkImprovementTipsStatus.Hidden,
                    modemResetTipStatus: TipStatus.Visible,
                    callbackTipStatus: TipStatus.Hidden,
                }
            }
        } else {
            // Case 3
            if (!downIsOk && downloadIndex > 0 || !upIsOk && uploadIndex > 0 ||
                downIsOk && downloadIndex >= downloadIndexThreshold || upIsOk && uploadIndex >= uploadIndexThreshold) {
                return {
                    partialServiceDialogKind: PartialServiceDialogKind.None,
                    networkImprovementTipsStatus: NetworkImprovementTipsStatus.OnFirstPlace,
                    modemResetTipStatus: TipStatus.Hidden,
                    callbackTipStatus: TipStatus.Hidden,
                }
            }
        }
    } else {
        // Case 6
        if ((!downIsOk && downloadIndex >= downloadIndexThreshold ||
            udpIsOk && input.currentDownloadSpeed <= 0.3 * bookedDownloadSpeedMax && downloadIndex >= downloadIndexThreshold ||
            !upIsOk && uploadIndex >= uploadIndexThreshold) &&
            input.callbackCounter > 0) {
            return {
                partialServiceDialogKind: PartialServiceDialogKind.Callback,
                networkImprovementTipsStatus: NetworkImprovementTipsStatus.Hidden,
                modemResetTipStatus: TipStatus.Hidden,
                callbackTipStatus: TipStatus.Visible,
            }
        }
    }

    return defaultState;
}

export const partialServiceSendAnalytics = (speedtestId: string): AppThunk => (dispatch, _) => {
    if (VARIANT === "SPLUS3") {
        dispatch(
            matomoPushEvent({
                category: "Partial Service",
                action: "Speedtest Started",
                name: { ident: speedtestId },
            })
        );
    }
}