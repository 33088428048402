import { logger } from "./helper/logger";
import { sentryReportsBeforeUnload, updateBeforeUnloadCaused } from "./features/sentryReports/sentryReportsSlice";
import { store } from "./store";
import { VARIANT } from "./config";
import { cancel } from "./features/measurement/measurementSlice";
import { currentSubscription } from "./networkQuality";
import { isSpeedtestRunning } from "./features/measurement/utils";

const onBeforeUnload = (e: BeforeUnloadEvent) => {
    logger.info("onBeforeUnload", e);

    if (!isSpeedtestRunning(store.getState().measurement)) {
        return;
    }

    sentryReportsBeforeUnload();
    store.dispatch(updateBeforeUnloadCaused(true));

    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

    // Chrome requires returnValue to be set
    const confirmationString = "Momentan läuft eine Messung!";
    e.returnValue = confirmationString;
    return confirmationString;
}

const onUnload = (e: Event) => {
    logger.info("onUnload", e);
    store.dispatch(cancel(currentSubscription.subscription));
}

export const addListeners = () => {
    logger.info("Add event listener");
    if (VARIANT === "SPLUS3") {
        window.addEventListener("beforeunload", onBeforeUnload);
    }
    window.addEventListener("unload", onUnload);
}