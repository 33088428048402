import React, { ReactElement, useEffect, useState } from "react";
import { appWithTranslation } from "next-i18next";
import "../styles/app.scss";
import { AppProps } from "next/app";
import { store } from "../src/store"
import { VARIANT } from "../src/config";
import { isRunningInBrowser } from "../src/helper/ssr";
import { ILinkInterceptor, ISpeedtestPlus, IStoreTest } from "../src/types";
import { cancel } from "../src/features/measurement/measurementSlice";
import { getIsSupported } from "../src/helper/utils/compatibility";
import { currentSubscription } from "../src/networkQuality";
import GigaTvPage from "../src/pages/app/GigaTvPage";
import NonePage from "../src/pages/app/NonePage";
import OldBrowserPage from "../src/pages/app/OldBrowserPage";
import SPlus3ViewPage from "../src/pages/app/SPlus3ViewPage";
import { createNqError } from "@visonum/network-quality-sdk";
import {getCurrentLang, getUrlParamValue} from "../src/helper/utils";
import { reportNQError } from "../src/features/errors/errorSlice";
import { isSpeedtestRunning } from "../src/features/measurement/utils";
import {sentryReportsCancellationDialogStopMeasurement} from "../src/features/sentryReports/sentryReportsSlice";
import {Phase} from "../src/features/measurement/types";

declare global {
  interface Window {
    LinkInterceptor: ILinkInterceptor;
    SpeedtestPlus: ISpeedtestPlus;
    AndroidNetworkQualitySDK: {};
    storeTest: IStoreTest;
    Cypress?: unknown;
  }
}

if (isRunningInBrowser()) {
  if (getUrlParamValue(window.location.href, "maintenance") !== null) {
    setTimeout(() => {
      store.dispatch(reportNQError(createNqError("Sm-1", "Maintenance Emulation")));      
    }, 500);    
  }

  const isSpeedtestRunningGlobal = () => {
    const state = store.getState().measurement;
    return isSpeedtestRunning(state);
  }

  switch (VARIANT) {
    case "SFA":
      window.SpeedtestPlus = {
        isSpeedtestRunning: (): boolean => {
          return isSpeedtestRunningGlobal();
        },

        cancelSpeedtest: (): Promise<void> => {
          const measurementState = store.getState().measurement;
          const dispatch = store.dispatch;

          sentryReportsCancellationDialogStopMeasurement();
          if (measurementState.kind !== Phase.Aborting && isRunningInBrowser()) {
            const lang = getCurrentLang();
            const targenPath = (lang === "de" ? "/" : "/" + lang)
            return dispatch(cancel(currentSubscription.subscription)).then(() => {
              window.location.assign(targenPath);
            });
          }
          return Promise.resolve();
        }
      }
      break;

    case "TV":
    case "SPLUS3":
      break;
    default:
      throw createNqError("Cl-3", `Unknown VARIANT: "${VARIANT}"`);
  }
}

enum ViewKind {
  None,
  Default,
  Admin,
  OldBrowser,
}

const App = ({ Component, pageProps, router }: AppProps): ReactElement => {
  const [viewKind, setViewKind] = useState(ViewKind.Default);

  useEffect(() => {
    switch (router.route) {
      case "/RgyhV5aRSi":
        setViewKind(ViewKind.Admin);
        break;
      default:
        if (VARIANT !== "TV") {
          const userAgent = navigator.userAgent;
          if (!getIsSupported(userAgent)) {
            setViewKind(ViewKind.OldBrowser);
          }
        }
        break;
    }
  }, []);

  switch (viewKind) {
    case ViewKind.None:
    case ViewKind.Admin:
      // Note 1596411282: CMS Page is not properly working from localhost:3000 independent from AdminPage removal
      return <NonePage />
    case ViewKind.OldBrowser:
      return <OldBrowserPage />
    case ViewKind.Default:
      switch (VARIANT) {
        case "TV":
          return <GigaTvPage pageProps={pageProps} Component={Component} router={router} />
        case "SPLUS3":
        case "SFA":
          return <SPlus3ViewPage pageProps={pageProps} Component={Component} router={router} />
      }
  }
}

export default appWithTranslation(App);
