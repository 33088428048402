import { css } from "@emotion/react";
import { freeze } from "immer";
import { vodafoneRed } from "../common";

const vodafoneGreen = "#00697C";
const defaultForeground = "#333333";

const indicatorMinimalColorValue = "#EB6100";
const indicatorAverageColorValue = "#FECB00";
const indicatorMaximalColorValue = "#008A00";

const indicatorOptimalColorValue = "#008A00";
const indicatorGoodColorValue = "#80C480";
const indicatorEnoughColorValue = "#FECB00";

const chartColorRouterDownload = vodafoneRed;
const chartColorDownload = "#0095B0";
const chartColorUpload = "#9e9c9c";

export const lightTheme = freeze({
    colors: {
        vodafoneRed,
        tariffInfo: vodafoneGreen,
        defaultForeground,
        chartColorRouterDownload,
        chartColorDownload,
        chartColorUpload,                
    },
    background: {
        primary: css({ backgroundColor: "#F2F2F2" }),
        white: css({ backgroundColor: "#fff" }),
        separator: css({ backgroundColor: "#AFAFAF" }),
        tableDevider: css({ backgroundColor: "#4A4D4E" }),
        monochrome600: css({ backgroundColor: "#0D0D0D" }),
        vodafoneGreen: css({ backgroundColor: vodafoneGreen }),
        vodafoneGradient: css({ background: "linear-gradient(45deg, #BD0000, #FF0000)" }),
        vodafoneRed: css({ backgroundColor: vodafoneRed }),
        defaultTransparent: css({ backgroundColor: "#B6B6B6" }),
        dialog: css({ background: "rgba(51, 51, 51, 0.79)" }),
        inverted: css({ backgroundColor: "#333333" }),
        transparent: css({ backgroundColor: "transparent" }),

        monochrome3: css({ background: "#BEBEBE" }),
        indicatorMinimal: css({ background: indicatorMinimalColorValue }),
        indicatorAverage: css({ background: indicatorAverageColorValue }),
        indicatorMaximal: css({ background: indicatorMaximalColorValue }),
        indicatorOptimal: css({ background: indicatorOptimalColorValue }),
        indicatorGood: css({ background: indicatorGoodColorValue }),
        indicatorEnough: css({ background: indicatorEnoughColorValue }),

        chartBarRouterDownload: css({ background: chartColorRouterDownload }),
        chartBarDownload: css({ background: chartColorDownload }),
        chartBarUpload: css({ background: chartColorUpload }),
    },
    foreground: {
        primary: css({ color: defaultForeground }),
        monochrome600: css({ color: "#0D0D0D" }),
        white: css({ color: "#fff" }),
        black: css({ color: "#000" }),
        monochrome500: css({ color: "#262626" }),
        monochrome4: css({ color: "#717171" }),
        color404: css({ color: "#111928" }),
        red: css({ color: "#BD0000" }),
        text: css({ color: "#2D2D2D" }),
        modemErrorContent: css({ color: "#333333" }),
        modemErrorTitle: css({ color: "#E60000" }),
    },
    shadow: {
        primary: css({ boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.16)" }),
        startButton: css({ filter: "drop-shadow(0px 1.503105640411377px 6.012422561645508px rgba(0, 0, 0, 0.20))" }),
        mainCard: css({ boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.10)" }),
        containerWide: css({ boxShadow: "0px 0px 10px 0px rgba(51, 51, 51, 0.15)" }),
        checkBox: css({ boxShadow: "0px 1px 2px 0px rgba(31, 41, 55, 0.08)" }),
    },
    border: {
        solidGray100: css({ border: "1px solid #CCC" }),
        solidGreen: css({ border: `1px solid ${vodafoneGreen}` }),
        solidDark: css({ border: `2px solid #0D0D0D` }),
        solidDark1: css({ border: `1px solid #0D0D0D` }),
        checkBox: css({ border: `1px solid #D1D5DB` }),
        checkBoxBoolean: css({ border: `1px solid #0D0D0D` }),
    }
} as const);